export const basicTerm = `BeeCruise株式会社(以下「当社」といいます)は、当社が運営する「kibotos」の利用について、以下のとおり本規約を定めます。

第1条（定義）
本規約においては、次の各号記載の用語はそれぞれ次の意味で使用します。
「kibotos」(以下「当サイト」といいます)とは、商品またはサービスの提供情報掲載、オンラインによる商品またはサービスの提供機能を持ったシステムで、当社が本規約に基づいてインターネット上で運営するサイトをいいます。
「利用者」とは、当サイトにアクセスする者をいいます。
「会員」とは、利用者のうち、当社が会員として入会を認め、会員として登録された者をいいます。
「本サービス」とは、当社が本規約に基づき当サイトを利用する者に対し、提供するサービスをいい、サービスの内容、種類については、当社の独自の判断により随時変更、増減が行なわれるものとし、その通知は随時、当サイト上での表示、または電子メールその他の通信手段を通じて行なわれるものとします。

第2条（規約の範囲および変更）
1 本規約は、本サービスの利用に関し、当社および利用者に適用するものとし、利用者は当サイトを利用するにあたり、本規約を誠実に遵守するものとします。
2 当社が別途当サイト上における掲示またはその他の方法により規定する個別規定および当社が随時利用者に対し通知する追加規定は、本規約の一部を構成します。本規約と個別規定および追加規定が異なる場合には、個別規定および追加規定が優先するものとします。
3 当社は利用者の承諾なく、当社の独自の判断により、本規約を変更する事があります。この場合、当サイトが提供するサービスの利用条件は変更後の利用規約に基づくものとします。当該変更は、当サイト上の一般掲示またはその他当社が適当と認めるその他の方法により通知した時点より効力を発するものとします。
4 規約の変更に伴い、利用者に不利益、損害が発生した場合、当社はその責任を一切負わないものとします。

第3条（利用者の地位および制限事項）
1 利用者の地位
当サイトにおいて利用者は、提供される本サービスのいずれかを享受する時点において（ここにいう享受には、情報の閲覧も含みます）、本規約に合意したものとみなされ、同時に当サイトにおける利用者としての地位を得るものとします。
2 利用者に対する制限事項
利用者は、以下に掲げる行為は行ってはならないものとします。
(1) 当サイトが指定した方法以外の方法によって、当サイトを利用する行為。
(2) 他者になりすまして本サービスを利用する行為。
(3) 当サイトが認める以外の方法で、本サービスに関連するデータのリンクを、他のデータ等へ指定する行為。
(4) 当サイトを利用するコンピュータに保存されているデータへ不正アクセスする、またはこれを破壊もしくは破壊するおそれのある行為。
(5) 本サービスの運営を妨害する行為。
(6) 本サービスを使用した営業活動ならびに営利を目的とした利用およびその準備を目的とした利用（商品等の日本国内外への転売およびオークション出品を目的とする利用を含む。）。但し、当社が別途承認した場合には、この限りではありません。
(7) 他の利用者の個人情報を収集したり、蓄積すること、またはこれらの行為をしようとする事。
(8) 公序良俗に反する行為およびその他国内外の法令に反する行為。
(9) その他当社が不適当と判断する行為。

第4条（会員申込）
1 入会を希望する者（以下、「入会希望者」といいます。）は、会員となる本人が当社の定める手続により入会を申し込むものとします。
2 会員登録手続は、前項の申込に対する当社の承諾をもって完了するものとします。ただし、当社は、入会希望者が以下に定める事由のいずれかに該当することが判明した場合、 入会希望者の入会を認めないことがあり、入会を認めた後でも、入会を取り消すことがあります。
(1)入会希望者が過去に本規約違反等により会員資格を抹消された場合。
(2)入会希望者が申込の際に当社に届け出た事項に虚偽、誤記または記入もれがあった場合。

第5条（会員情報の管理）
1 会員は会員としての権利を第三者に利用させたり、貸与、譲渡、売買、質入等をしたりすることはできないものとします。
2 会員は、会員登録後に自己が登録した情報およびLINEアカウントに関して管理責任を負うものとし、不正に利用されたことにより当社に損害が生じた場合、会員は、当社に対し、その損害を賠償するものとします。
3 会員に、LINEおよびLINEアカウントを起因とした損害が生じても、当社は一切の責任を負わないものとします。

第6条（利用資格および会員資格の停止・除名）
当社は、以下の事由がある場合、利用者および会員に何ら事前の通知または催告をすることなく、利用の停止、会員資格の除名することができるものとします。
(1) 利用者が代金を定められた時期までに支払わなかった場合。
(2) 利用者に対し、差押、仮差押、仮処分、強制執行、破産、民事再生、会社更生、特別清算の申し立てがなされた場合、または、利用者が自ら破産、民事再生、会社更生、特別清算の申し立てをした場合。
(3) その他、利用者が本規約に違反した場合や、当社が不適格と判断した場合。

第7条（本サービスの中断、停止）
1 当社は以下のいずれかの事由に該当する場合、当社の独自の判断により、利用者に事前に通知することなく本サービスの一部もしくは全部を一時中断、または停止することがあります。
(1) 本サービスのための装置、システムの保守点検、更新を定期的にまたは緊急に行う場合。
(2) 火災、停電、天災などの不可抗力により、本サービスの提供が困難な場合。
(3) 第一種電気通信事業者の任務が提供されない場合。
(4) その他、運用上あるいは技術上当社が本サービスの一時中断、もしくは停止が必要であるか、または不測の事態により、当社が本サービスの提供が困難と判断した場合。
(5)本サービスのシステム連携先の不具合により、本サービスの提供が困難な場合。
2 当社は、本サービスの提供の一時中断、停止等の発生により、利用者または第三者が被ったいかなる不利益、損害について、理由を問わず一切の責任を負わないものとします。

第8条（リンクの扱いについて）
当サイトが提供する各種サービスの中から他のサイトへリンクをしたり、第三者が他のサイトへのリンクを提供している場合、当社は当サイト以外のサイトについては、何ら責任は負いません。この場合、当該サイトに包括され、また当該サイト上で利用が可能となっているコンテンツ、広告、商品、サービスなどについても同様に一切責任を負いません。当社は、それらのコンテンツ、広告、商品、サービスなどに起因または関連して生じた一切の損害についても賠償する責任は負いません。

第9条（著作権)
1 利用者は、当サイトを通じて提供されるいかなるコンテンツについても、方法の如何を問わず、当社および権利者の承諾を得ないで、著作権法で定める利用者個人の私的使用の範囲を超える複製、販売、出版、その他の用途に使用するなどして著作権を侵害しないものとします。
2 利用者は、いかなる方法においても、当社および権利者の承諾を得ないで、第三者をして、当サイトを通じて提供されるいかなる情報も使用させたり、公開させたりすることはできないものとします。
3 本条の規約に違反して問題が発生した場合、利用者は、自己の責任と費用においてかかる問題を解決するとともに、当社に何らの迷惑または損害を与えないものとします。

第10条(賠償責任)
1 本サービスの提供、遅滞、変更、中断、中止、停止、もしくは廃止、提供される情報等の流出もしくは焼失等、またはその他本サービスに関連して発生した利用者または第三者の損害について、当社は一切の責任を負わないものとします。但し、本サービスを利用して当社から購入した商品等に関して、当社の責めに帰すべき事由または商品等の隠れたる瑕疵に基づき利用者に損害が生じた場合には、本規約第16条1項に準じます。
2 利用者が本サービス利用によって第三者に対して損害を与えた場合、利用者は自己の責任と費用をもって解決し、当社に損害を与えることのないものとします。利用者が本規約に反した行為、または不正もしくは違法な行為によって当社に損害を与えた場合、当社は当該利用者に対して相応の損害賠償の請求ができるものとします。

第11条（プライバシーポリシー）
利用者による本サービスの利用に関連して当社が知り得る利用者の情報の管理および取扱いについては、当社が別途定めるプライバシーポリシーによるものとします。

第12条（売買契約）
1 利用者は、当社の定める方法により、サービスの利用または商品の購入を申し込むものと
します。
2 利用者からの申し込みに対し当社が承諾することにより利用者と当社との間に売買契約（サービスの提供契約を含むものとします。以下同じ。）が成立するものとします。
3 利用者は、利用者が利用または購入を申し込んだ後は、個別規約により取消が認められて
いる場合を除き、売買契約の成立前でも、申込を取り消すことはできないものとします。
4 当社は、利用者からの申し込みに対し当社が承諾するか否かを自由に判断することがで
きるものとします。
5 利用者は、当社の定める方法により当社の定める時期までにサービスまたは商品（以下
「商品等」といいます）の代金を支払うものとします。
6 利用者は、当社の定める時期までに商品等の代金を支払わなかった場合、当社に対し、
14.6パーセントの割合による遅延損害金を支払うものとします。
7 利用者は、当社との売買契約に基づき発生した権利を第三者に譲渡し、担保に供するなど一切の処分をすることはできないものとします。
8 当社は、利用者が当社の定める時期までに商品等の代金を支払わなかった場合、何らの催告なく売買契約を解除することができるものとします。 当社が売買契約を解除した場合でも、当社は、利用者に対し、当社に生じた損害の賠償を請求することができるものとします。
9 当社は、以下に定める事由のいずれかに該当する場合は、売買契約を承諾した後でも、承諾を取り消すことができるものとします。
(1) 利用者が未成年者で親権者の同意を得ていない場合。
(2) 利用者が利用可能区域外に居住する場合。
(3) 利用者が本規約に違反した場合。
(4) 利用者が申込の際に当社へ届け出た事項に虚偽、誤記または記入もれがあった場合。
(5) 利用者が当社に対する債務の支払を怠ったことがある場合。
(6) 利用者が当社の運営・サービス提供または他の利用者のサイト利用を妨害し、またはそれらに支障をきたす行為を行った場合。
(7) 利用者が合理的な理由なく返品または受取拒否をしたことがある場合。
(8) 当社が当サイトにおいて提示した商品等の代金、その他の販売条件に、明らかな誤記があったことが判明した場合。
(9) その他当社が不適当と判断した場合。

第13条（商品の配送や送料）
送料および手数料等に関する定めは、特定商取引に関する法律に基づく表示および各商品等の個別規約に記載をしております。

第14条（注文完了後のキャンセル）
利用者都合による注文完了後のキャンセルは、個別規約で定められている場合を除き、原則として受け付けません。

第15条（交換・返品・返金）
1 利用者の事情による返品
利用者の事情による交換・返品は原則として受け付けません。
2 利用者の事情によらない交換・返品
利用者は、以下に定める利用者の事情によらない交換・返品に限り、受け取り済み商品の交換・返品をすることができます。また、サービスの不備については、返金等を受けることができます。
この場合の送料は当社が負担いたします。
商品等の到着後、個別規約に記載の期間内に当サイトにご連絡ください。
・注文した商品が不良の場合。
・注文した商品に損傷がある場合。
・注文されたものと異なる商品が届けられた場合。
・注文したサービスとは異なるサービスを受けた場合。


第16条（当社の責任の範囲）
1 当社は、利用者が当社から購入した商品等に関して、当社の責めに帰すべき事由または商品等の隠れた瑕疵に基づき利用者に損害が生じた場合については、各個別規約に記載のとおり、賠償の責任を負うものとします。
2 当社は、利用者が当サイトを利用する際に、コンピュータウイルスなど有害なプログラム等による損害を受けないことを保証しないものとします。
3 当社は、利用者が当サイトを利用する際に使用するいかなる機器、ソフトウェアについても、その動作保証を一切しないものとします。
4 当社は、利用者が当サイトを利用する際に発生する通信費用について、一切負担しないものとします。
5 本条は、利用者に対する当社の責任の全てを規定したものであり、当社は、いかなる場合でも、利用者の逸失利益、間接損害、特別損害、弁護士費用その他の本条に規定のない損害を賠償しないものとします。

第17条（反社会的勢力の排除）
1 会員は、入会申込および商品等の購入申込日現在、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
(1) 暴力団
(2) 暴力団員
(3) 暴力団準構成員
(4) 暴力団関係団体
(5) 総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団
(6) 本項第1号から第5号の者と社会的または経済的関係がある者
(7) その他前各号に準ずる者
2 会員は、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約します。
(1) 暴力的な要求行為
(2) 法的な責任を超えた不当な要求行為
(3) 取引に関して、偽計を用いまたは威力を用いて相手方の信用を毀損し、または業務を妨害する行為
(4)その他前各号に準ずる行為
3 当社は、会員が第1項各号のいずれかに該当し、もしくは前項各号のいずれかに該当する行為をし、または第1項の規定に基づく表明・確約に関して虚偽の申告をしたことが判明した場合は、相手方に対し売買契約を解除することができるものとします。

第18条（準拠法）
本規約の成立、効力、履行および解釈に関しては、日本法が適用されるものとします。

第19条（合意管轄）
本規約に関して紛争が生じた場合、当社本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。

2020年2月28日制定`;

export const laundryTerm = `kibotos宅配洗濯代行利用規約
 
 
第1条 利用規約について
(1)このkibotos宅配洗濯代行 利用規約(以下「当規約」)は、BeeCruise株式会社(以下「当社」)が提供するkibotos宅配洗濯代行サービス(以下「当サービス」)の利用について、当社と利用者がお互いに遵守する事項を定めたものです。
(2)当規約は、当社が必要と認めるときには事前の予告なく変更される場合があります。予めご了承ください。
(3)当規約に変更があった場合、当社はkibotos ウェブサイトにてこれを知らせるものとします。
第2条 利用資格
当サービスをご利用いただくには、当規約に同意をいただく必要があります。
第3条 会員登録、退会について
(1)当サービスをご利用いただくには、利用者によりLINEを通じてkibotos の会員登録画面にて、会員登録を行う必要があります。
(2)登録された個人情報は、当サ－ビスの運営会社であるBeeCruise株式会社の個人情報保護方針に則って適切かつ厳重に管理し、不正利用や紛失が起きぬよう、安全対策を講ずるものとします。
(3)登録された情報に変更が生じた場合には、kibotos ウェブサイト内[お問い合わせ]より、ご連絡をしていただく必要があります。万が一、会員よりご連絡がなく、その結果として、当社からの連絡が届かない、または、洗濯物の集荷/配達が行われない等のトラブルが生じた場合、当社は一切の責任を負わないものとします。
(4)当サービスの退会を希望される場合、LINEのお友達登録を削除してください。当該削除によって、退会したものとみなします。
(5)退会後はkibotos の各サービスがご利用できなくなります。予めご了承ください。
第4条 注文、注文内容の変更、キャンセルについて
(1)当サービスは、kibotos ウェブサイトにてご注文いただけます。
(2)会員都合により注文内容の変更を希望される場合、kibotos ウェブサイトで変更を承ります。
(3)会員都合による注文内容の変更およびキャンセルを希望された場合でも、既に処理が行われているなどの理由により、変更およびキャンセルを承れない場合があります。予めご了承ください。
(4)集荷後、キャンセルはできません。会員都合によるキャンセルを希望される場合、集荷の前にご連絡いただく必要がございます。
・集荷前のキャンセル希望…集荷の前にご連絡いただいた場合、キャンセル料は発生しません。
・集荷後のキャンセル希望…原則としてキャンセルはできません。会員のご都合でサービスを行わずお返しする場合も返金などの要求には応じられません。予めご了承ください。
・集荷時、会員不在もしくは宅配ボックスに入っていないことにより専用ランドリーバッグを回収できなかった場合…キャンセル扱いとさせていただきます。
※当社の基準に基づき、処理を行えないと判断した洗濯物につきまして、サービスを行わずお返しをする場合も原則返金は行いません。
(5)会員都合による注文内容の変更およびキャンセルを希望される場合、kibotos ウェブサイトに記載のあるカスタマーサポートまでメールにてご連絡いただく必要があります。
第5条 料金について
サービス料金および集荷/配達料金は事前の予告なく変更される場合があります。集荷/配達料金に変更があった場合、当社はkibotos ウェブサイトにてこれを知らせるものとします。
第6条 当サービスについて
(1)当サービスは、専用ランドリーバッグにてお預かりし、処理を行うサービスです。
 
(2)当サービスは、会員の洗濯物を会員にかわり当社の提携先企業が洗濯するものです。お預かりした洗濯物はそのまま水洗い、乾燥を行います。専用ランドリーバッグには必ず、水洗い、乾燥が可能な洗濯物のみをお入れください。
(3)当サービスは、ドライクリーニングを行うものではありません。また、シミ抜き、のりづけ、アイロンによるプレス作業は行いません。
(4)洗濯物に付属の装飾品はお預かりいたしません。必ず事前に外したうえでご依頼ください。付属の装飾品の紛失などのトラブルについて、当社は責任を負いかねます。予めご了承ください。
(5)洗濯物に付属のボタンなどが取れていた場合、また、水洗い、乾燥により洗濯物に付属のボタンなどが取れた場合、いずれの場合も当社は取れたボタンなどを保管および返却する義務を負わず、また、取れたボタンなどや、類似するボタンなどをつけ直すサービスは行いません。ボタンなどが取れそうな洗濯物に関し、専用ランドリーバッグに入れる前に、会員にて予め外しておかれることをお勧めいたします。
(6)洗濯表示上、水洗い不可となっているもの、タンブル乾燥不可となっているもの、洗濯表示タグがないものにつきましては、どの洗濯コースを選択された場合も、会員が選択した洗濯コースのサービスを行うことに会員の了承が得られているものと判断し、そのままサービスを行わせていただきます。サービスを行ったことで生じた色落ち・色移り・縮みなどのトラブルについて、当社は責任を負いかねます。予めご了承ください。
(8)水洗い、タンブル乾燥により、縮み・破れ・ほつれ・色落ち・変形などが生じる可能性があるもの、また、ビーズ・刺しゅう・プリントが施されたものなど破損や剥落の可能性があるものがお預かりした洗濯物に含まれていた場合も、サービスを行うことに、会員の了承が得られているものと判断し、そのままサービスを行わせていただきます。サービスを行った後のトラブルについて、当社は責任を負いかねます。予めご了承ください。
(9)お預かりした洗濯物に縮み・破れ・ほつれがあるものが含まれていた場合も、サービスを行うことに会員の了承が得られているものとし、そのままサービスを行わせていただきます。サービスを行った後のトラブルについて、当社は責任を負いかねます。予めご了承ください。
(10)極度の色落ち、色移り、縮み、破損、剥落、または変形が予見されるなど、当社にてサービスを行えないと判断した洗濯物は、サービスを行わずにそのままお返しさせていただきます。この場合、サービスを行わなかった洗濯物に対する一部返金などの要求には応じられません。予めご了承ください。
(11)水洗いを行うことにより、洗濯物表面の汚れが除去され、洗濯前には目立たなかった洗濯物の日焼けや色落ちなどが目立ちやすくなる場合があります。これらの現象に基づくお申し出について、当社では一切の責任を負いかねます。予めご了承ください。
(12)湿り気を含んだ洗濯物、濡れた洗濯物は雑菌発生のもととなる為、衛生上の観点から、専用ランドリーバッグには入れないよう、お願いいたします。
(13)おむつ・ペット用品・その他これらに類する洗濯物は、衛生上の観点から、お預かりできません。お預かりした洗濯物にこれらが含まれていた場合、もしくは当社にてこれらが含まれていると判断した場合、サービスを行わずそのままお返しさせていただきます。この場合、サービスを行わなかった洗濯物に対する一部返金などの要求には応じられません。予めご了承ください。
(14)ペットの毛・人毛・埃・その他これらに類するものが大量に付着している洗濯物は、衛生上の観点および他の洗濯物への付着や機器の故障の原因となることから、お預かりできません。お預かりした洗濯物にこれらが含まれていた場合、もしくは当社にてこれらが含まれていると判断した場合、サービスを行わずそのままお返しさせていただきます。この場合、サービスを行わなかった洗濯物に対する一部返金などの要求には応じられません。予めご了承ください。
(15)伝染病に感染している、もしくは、伝染病に感染している可能性のある会員の洗濯物は、衛生上の観点から、お預かりできません。
(16)専用ランドリーバッグに、汚物や吐瀉物が付着している洗濯物が入っていた場合、もしくは当社にてこれらが含まれていると判断した場合、衛生上の観点から、サービスを行わずにバッグごとそのままお返しさせていただきます。この場合、サービス料金はお戻しいたしますが、キャンセル料として1,000円ご請求致しますこと予めご了承ください。
(17)お子様の軽度の食べこぼしにつきましては、汚れ部分を濯ぎ、乾燥されたもので、当社にて処理が行えると判断したものに限り、サービスを行わせていただきます。
(18)泥・食べこぼし・血液・その他汚れが付着している洗濯物につきましては、お預かりしたバッグのその他洗濯物と一緒に洗濯乾燥を行わせていただきます。汚れが付着しているものを別洗いするなどの対応は行いません。
(19)全てのコースに於いて、事前洗いやシミ抜きの対応は行っておりません。水洗い、タンブル乾燥時に、洗濯物同士で汚れ移りが生じた場合について、当社では責任を負いかねます。予めご了承ください。
(20)当サービスは、圧縮された状態での洗濯物のお預かりはしておりません。万が一圧縮された状態で洗濯物をお預けいただいた場合は、圧縮しない状態にて実際に生じると思われるサービス料金を再計算し、不足額を別途請求させていただきます。予めご了承ください。
(21)会員の過失により、専用ランドリーバッグが破損した場合は有償で交換となります。洗濯物の詰め過ぎによる専用ランドリーバッグの破損にご注意ください。
(22)当サービスをご利用の会員におかれましては、安全管理上の観点から、お預かり日より10日以内にお引き取りをお願いいたします。
(23)配送時不在、会員と連絡がつかないなどの理由により、返却予定日から90日を経過しても返却が完了しないお預かり品につきましては、会員が権利を放棄したとみなし、当社にて処分させていただきますのでご留意ください。この場合、処分したお預かり品に対する補償/賠償の要求には応じられません。
 
第7条 集荷、配送について
(1)洗濯物の集荷/配達は、集荷/配達を希望される地区に応じて、BeeCruise株式会社の自社ドライバー、または、委託配送業者により行われます。
(2)自社ドライバーによる集荷/配達時のトラブルや事故に関しましては、当社規定に基づいて対応を行うものとします。
(3)地震、豪雨災害等、当社の責めに帰すことのできない自然災害により、自社ドライバーによる集荷/配達に遅延が生じた場合、集荷/配送料金の返金には一切応じないものとします。
(4)委託配送業者による集荷/配達時のトラブルや事故に関しましては、委託配送業者の会社規定に基づいて対応を行うものとし、当社は一切の責任を負わないものとします。
 
第8条 補償、弁償について
(1)当社の責めに帰すべき事由に基づき、お預かりした洗濯物の紛失などの事故が生じた場合、1バッグの補償限度額を20,000円としてその範囲内で補償させていただきます。
(2)地震、豪雨災害等、当社の責めに帰すことのできない自然災害により、お預かりした洗濯物を減失、損失した場合は、民法の規定に基づき、当社はお預かりした洗濯物の損害の賠償を免れます。予めご了承ください。
 
2020年2月28日制定`;
